<template>
  <div class="news">
    <div class="list animated fadeInUp">
      <div class="title">
        <div class="titCol1">消息列表</div>
        <div class="titCol2">
          <el-badge :value="msgCount" :max="99" class="" :hidden="!msgCount">
            <div class="unread">
              <div class="boxes">
                <img src="../assets/image/news/icon1.png" />
                <span>未读消息</span>
              </div>
            </div>
          </el-badge>
          <div class="other-btn" v-if="isDel">
            <div class="multiple-delete" @click="setDel">批量删除</div>
            <div class="read" @click="setSign">标记已读</div>
          </div>
          <div class="other-btn" v-else>
            <div class="del-change multiple-delete" @click="cancelDel">
              取消删除
            </div>
            <div class="del-change multiple-delete self" @click="goDel">
              删除选中
            </div>
          </div>
        </div>
      </div>
      <div class="list-main">
        <div class="search">
          <input
            type=""
            class="search-input"
            placeholder="请输入想要找的消息"
            :class="isFocusShow ? 'active' : ''"
            @focus="getFocus"
            @blur="getBlur"
            v-model.trim="title"
          />
          <div class="buttons" @click="getSearch">搜索</div>
        </div>
        <div class="infos" v-blockLoadMore="loadMoreItemsData">
          <div
            class="info-items"
            :class="newIndex === index ? 'active' : ''"
            v-for="(item, index) in newLists"
            :key="item.id"
            @click.passive="changeNew(index, item.id)"
          >
            <div class="brief-R">
              <el-checkbox
                class="checkbox"
                v-model="item.checked"
                v-show="!isDel"
                @change="handleCheckedChange(item.id, $event)"
              ></el-checkbox>
              <img src="../assets/image/news/icon2.png" />
              <div class="brief-txt">
                <div class="tit">{{ item.title }}</div>
                <div class="abstract one-txt-cut">
                  {{ item.desc }}
                </div>
              </div>
            </div>
            <div class="brief-L">
              <p class="time">{{ item.time }}</p>
              <div class="status" v-if="!item.state">未读</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contents animated fadeInUp" v-if="msgInfo.title">
      <div class="msgs">
        <span>详情</span>
        <div class="" @click="delItems">删除</div>
      </div>
      <div class="new-title one-txt-cut">
        {{ msgInfo.title }}
      </div>
      <div class="out-box">
        <div class="new-other">
          <span>发件人：{{ msgInfo.sname }}</span>
          <span>发送时间：{{ msgInfo.created_time }}</span>
        </div>
      </div>
      <div class="new-content">{{ msgInfo.content }}</div>
    </div>
    <MyNav></MyNav>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getMsgInfoApi,
  getMsgApi,
  getMsgListApi,
  delMsgApi,
} from "@/request/api";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      newIndex: 0,
      newId: "",
      msgCount: null,
      isDel: true,
      isReadTipsShow: false,
      newLists: [],
      selectLength: [],
      isFocusShow: false,
      msgInfo: {},
      total: 20,
      current_page: 1,
      title: undefined,
    };
  },
  created() {
    sessionStorage.setItem("navLeft", "-100vh");
    if (this.$route.query.id) {
      this.getMsgInfoApi(this.$route.query.id);
      this.newId = this.$route.query.id;
    }
    this.getMsgListApi();
    this.getMsgApi();
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },

  methods: {
    ...mapMutations("user", ["SET_MSG_COUNT"]),
    loadMoreItemsData() {
      if (this.newLists.length && this.newLists.length == this.total) return;
      this.current_page++;
      this.getMsgListApi(this.current_page);
    },
    async delMsgApi(message_ids) {
      const { code } = await delMsgApi({
        message_ids,
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: "已删除",
          type: "success",
        });
        this.isDel = true;
        this.newLists = [];
        this.current_page = 1;
        this.getMsgListApi(1);
      }
    },
    async getMsgApi() {
      const { code, results } = await getMsgApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.msgCount = results.count;
      }
    },
    async getMsgListApi(pn = 1) {
      showFullScreenLoading();
      const { code, results } = await getMsgListApi({
        manor_id: this.farmID,
        title: this.title || undefined,
        pn,
        ps: 20,
      });
      if (code === 0) {
        this.newLists = [...this.newLists, ...results.data];
        this.total = results.count;
        this.current_page = results.pn;

        this.newLists.forEach((item, index) => {
          this.$set(this.newLists[index], "checked", false);
          this.$set(
            this.newLists[index],
            "time",
            item.created_time.substring(5, 10)
          );
        });
      }
      tryHideFullScreenLoading();
    },

    async getMsgInfoApi(id) {
      const { code, results } = await getMsgInfoApi({
        message_id: id,
      });
      if (code === 0) {
        this.msgInfo = results;
        this.msgInfo.state = 1;
      }
    },
    countArrNum(arr, res) {
      let newArrays = arr.filter(function (item) {
        return item.state == res;
      });
      return newArrays.length;
    },
    getFocus() {
      this.isFocusShow = true;
    },
    getBlur() {
      this.isFocusShow = false;
    },

    delItems() {
      this.$confirm("确认删除该消息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delMsgApi([this.newId]);
          this.msgInfo = {};
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    goDel() {
      if (this.selectLength.length < 1) {
        this.$notify({
          title: "提示",
          message: "未选中消息",
        });
        return;
      } else {
        this.$confirm("确认删除选中的消息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.delMsgApi(this.selectLength);
          })
          .catch(() => {
            this.$notify({
              title: "提示",
              message: "已取消操作",
            });
          });
      }
    },
    handleCheckedChange(id, value) {
      if (value) {
        this.selectLength.push(id);
      } else {
        this.selectLength.splice(this.selectLength.indexOf(id), 1);
      }
    },
    setDel() {
      this.isDel = !this.isDel;
    },
    cancelDel() {
      this.isDel = !this.isDel;
      this.newLists.forEach((item, index) => {
        this.$set(this.newLists[index], "checked", false);
      });
    },
    setSign() {
      this.newLists.forEach((item) => {
        item.state = 1;
      });
      this.isReadTipsShow = true;
      this.msgCount = 0;
      this.SET_MSG_COUNT({ msgCount: 0 });
    },
    changeNew(index, id) {
      this.newId = id;
      this.getMsgInfoApi(id);
      this.newIndex = index;
      this.$set(this.newLists[index], "state", 1);
      let isReadStatus = this.newLists.every((item) => {
        return item.state == 1;
      });
      this.msgCount = this.countArrNum(this.newLists, 0);
      this.SET_MSG_COUNT({ msgCount: this.msgCount });
      if (isReadStatus) {
        this.isReadTipsShow = true;
      }
    },
    getSearch() {
      this.newLists = [];
      this.getMsgListApi(1);
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  width: 100vw;
  height: 100vh;
  background-color: #071a2c;
  height: 100vh;
  padding: 62px 0 20px 48px;
  display: flex;
  overflow: hidden;
  color: #fff;
  .list {
    width: 482px;
    height: 100%;

    .title {
      margin-bottom: 5px;
      height: 135px;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #0e2138;
      padding: 35px 48px 0;

      .titCol1 {
        width: 100%;
        font-size: 22px;
        position: relative;
        padding: 0 11px;
        margin-bottom: 20px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 22px;
          background: #3e90e5;
          border-radius: 2px;
        }
      }

      .titCol2 {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .unread {
          position: relative;
          width: 112px;
          height: 36px;
          background: #0e2f53;
          border-radius: 3px;

          .boxes {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 18px;
              height: 19px;
              margin-right: 7px;
            }

            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
          }

          .tips {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 21px;
            height: 21px;
            background: #d30000;
            border-radius: 50%;
            line-height: 21px;
            text-align: center;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .other-btn {
          display: flex;
          align-items: center;

          .multiple-delete {
            width: 90px;
            height: 34px;
            border: 1px solid #385576;
            border-radius: 3px;
            font-size: 14px;
            color: #3e90e5;
            margin-right: 14px;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
          }

          .read {
            width: 90px;
            height: 34px;
            border: 1px solid #2d5939;
            border-radius: 3px;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            color: #67c23a;
          }
          .del-change {
            width: 90px;
            height: 34px;
            border: 1px solid #385576;
            border-radius: 3px;
            font-size: 14px;
            color: #3e90e5;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
            &:last-of-type {
              margin-right: 0;
            }
          }
          .self {
            color: #d30000;
            border-color: #d30000;
          }
        }
      }
    }

    .list-main {
      width: 100%;
      height: calc(100% - 138px);
      display: flex;
      flex-direction: column;
      background-color: #0e2138;
      padding: 13px 0 0;

      .search {
        display: flex;
        align-items: center;
        padding: 0 48px;

        .search-input {
          width: 282px;
          height: 40px;
          border: 1px solid #385576;
          border-radius: 3px;
          background-color: #081c30;
          color: #57708d;
          font-size: 14px;
          padding-left: 22px;
          outline: none;

          &.active {
            border-color: #3e90e5;
          }
        }

        .buttons {
          margin-left: 15px;
          width: 90px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #3e90e5;
          border-radius: 3px;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .infos {
        width: 100%;
        height: calc(100% - 44px);
        overflow-y: scroll;
        &:empty::after {
          content: "暂无信息";
          color: #3e90e5;
          font-size: 16px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .info-items {
          width: 100%;
          height: 91px;
          cursor: pointer;
          padding: 0 48px;
          display: flex;
          background: #0e2138;
          border-radius: 3px;

          &:hover {
            background: #081c30;
          }

          &.active {
            background: #081c30;
          }

          .info-items.active {
            background: #081c30;
          }

          .brief-R {
            width: calc(100% - 46px);
            height: 100%;
            display: flex;
            align-items: center;

            .checkbox {
              /deep/.el-checkbox__inner {
                border: 1px solid #385576;
                background-color: #0e2138;
              }

              /deep/.is-checked .el-checkbox__inner {
                background-color: #409eff;
              }
            }

            img {
              margin: 0 12px 0 8px;
              width: 50px;
              height: 50px;
            }

            .brief-txt {
              display: flex;
              width: calc(100% - 50px);
              flex-direction: column;

              .tit {
                font-size: 16px;
                color: #ffffff;
                margin-bottom: 15px;
              }

              .abstract {
                font-size: 12px;
                color: #a9b8c8;
              }
            }
          }

          .brief-L {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 46px;
            padding-top: 26px;

            .time {
              color: #a9b8c8;
              font-size: 16px;
              margin-bottom: 16px;
            }

            .status {
              width: 45px;
              height: 18px;
              background: #d30000;
              border-radius: 9px;
              text-align: center;
              line-height: 18px;
              font-size: 12px;
              color: #ffffff;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
    }
  }

  .contents {
    flex: 1;
    width: calc(100% - 482px);

    .msgs {
      width: 100%;
      height: 86px;
      border-bottom: 1px solid #385576;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 100px;

      span {
        color: #3e90e5;
        font-size: 22px;
      }

      div {
        width: 90px;
        height: 40px;
        background: #d30000;
        border-radius: 3px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        color: #ffffff;
      }
    }

    .new-title {
      width: 100%;
      height: 92px;
      line-height: 92px;
      font-size: 24px;
      font-weight: bold;
      padding: 0 100px 0 47px;
      color: #ffffff;
    }

    .out-box {
      width: 100%;
      height: 46px;
      padding: 0 96px 0 47px;

      .new-other {
        width: 100%;
        height: 46px;
        line-height: 46px;
        background: #0c1e33;
        border-top: 1px solid #385576;
        border-bottom: 1px solid #385576;
        font-size: 16px;
        color: #ffffff;

        span {
          margin-right: 30px;
        }
      }
    }

    .new-content {
      width: 100%;
      height: calc(100% - 224px);
      padding: 35px 96px 35px 47px;
      color: #a9b8c8;
      font-size: 16px;
      line-height: 32px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }
    }
  }
}
</style>
